import React, { useState } from 'react';
import styles from './styles';
import { useTheme } from '@mui/material/styles';
import { Link } from 'gatsby';
import { Button, Grid, Typography, useMediaQuery } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


const Approach = ({ slideOne, slideTwo, slideThree, slideFour }) => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down(1000));

	const [invertedControls, setInvertedControls] = useState(false);
	const [selectedItem, setSelectedItem] = useState(0);

	const isBrowser = () => ![typeof window, typeof document].includes('undefined');

	isBrowser() && window.addEventListener('resize', () => {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	});

	return (
		<div
			className={`section-wrapper ${invertedControls ? 'section-wrapper-inverted' : ''}`}
			css={styles(theme)}
		>
			<Carousel
				selectedItem={selectedItem}
				infiniteLoop
				showStatus={false}
				swipeable={false}
				animationHandler='fade'
				onClickNext={() => {
					setSelectedItem(selectedItem + 1);
				}}
				onChange={item => setInvertedControls(item !== 0)}
			>
				<Grid
					sx={{ backgroundColor: slideOne?.slideOneColor }}
					container
					direction={mobile ? 'column' : 'row'}
					className={`section-wrapper`}
					justifyContent="flex-start"
					alignItems="center"
				>
					{!mobile ? (
						<>
							<Grid className="section-image" item>
								<img src="/img/group.svg" className="image" />
							</Grid>
							<Grid className="section-content" item>
								<Typography
									sx={{ color: `${slideOne?.slideOneParagraphColor} !important` }}
									className="section-title"
								>
									{slideOne?.slideOneTitle}
								</Typography>
								<Typography
									sx={{ color: `${slideOne?.slideOneParagraphColor} !important` }}
									className="section-paragraph"
								>
									{slideOne?.slideOneParagraph}
								</Typography>
							</Grid>
						</>
					) : (
						<>
							<Grid className="section-content" item>
								<Typography
									sx={{ color: `${slideOne?.slideOneParagraphColor} !important` }}
									className="section-title"
								>
									{slideOne?.slideOneTitle}
								</Typography>
							</Grid>
							<Grid className="section-image" item>
								<img src="/img/group.svg" className="image" />
							</Grid>
							<Grid className="section-content-mobile" item container jusitfyContent="flex-start">
								<Typography
									sx={{ color: `${slideOne?.slideOneParagraphColor} !important` }}
									className="section-paragraph"
								>
									{slideOne?.slideOneParagraph}
								</Typography>
							</Grid>
						</>
					)}
				</Grid>
				<Grid
					sx={{ backgroundColor: slideTwo?.slideTwoColor }}
					container
					className="section-wrapper"
					justifyContent="flex-start"
					alignItems="center"
				>
					{!mobile ? (
						<>
							<Grid className="section-image" item>
								<img className="image" src="/img/why.svg" />
							</Grid>
							<Grid className="section-content" item>
								<Typography
									sx={{ color: `${slideTwo?.slideTwoParagraphColor} !important` }}
									className="section-title"
								>
									{slideTwo?.slideTwoTitle}
								</Typography>
								<Typography
									sx={{ color: `${slideTwo?.slideTwoParagraphColor} !important` }}
									className="section-paragraph"
								>
									{slideOne?.slideOneParagraph}
								</Typography>
							</Grid>
						</>
					) : (
						<>
							<Grid className="section-content" item>
								<Typography
									sx={{ color: `${slideTwo?.slideTwoParagraphColor} !important` }}
									className="section-title"
								>
									{slideTwo?.slideTwoTitle}
								</Typography>
							</Grid>
							<Grid className="section-image" item>
								<img className="image" src="/img/why.svg" />
							</Grid>
							<Grid className="section-content-mobile" item container>
								<Typography
									sx={{ color: `${slideTwo?.slideTwoParagraphColor} !important` }}
									className="section-paragraph"
								>
									{slideTwo?.slideTwoParagraph}
								</Typography>
							</Grid>
						</>
					)}
				</Grid>
				<Grid
					sx={{ backgroundColor: slideThree?.slideThreeColor }}
					container
					className="section-wrapper"
					justifyContent="flex-start"
					alignItems="center"
				>
					{!mobile ? (
						<>
							<Grid className="section-image" item>
								<img className="image" src="/img/what.svg" />
							</Grid>
							<Grid className="section-content" item>
								<Typography
									sx={{
										color: `${slideThree?.slideThreeParagraphColor} !important`
									}}
									className="section-title"
								>
									{slideThree?.slideThreeTitle}
								</Typography>
								<Typography
									sx={{
										color: `${slideThree?.slideThreeParagraphColor} !important`
									}}
									className="section-paragraph"
								>
									{slideThree?.slideThreeParagraph}
								</Typography>
							</Grid>
						</>
					) : (
						<>
							<Grid className="section-content" item>
								<Typography
									sx={{
										color: `${slideThree?.slideThreeParagraphColor} !important`
									}}
									className="section-title"
								>
									{slideThree?.slideThreeTitle}
								</Typography>
							</Grid>
							<Grid className="section-image" item>
								<img className="image" src="/img/what.svg" />
							</Grid>
							<Grid className="section-content-mobile" item container>
								<Typography
									sx={{
										color: `${slideThree?.slideThreeParagraphColor} !important`
									}}
									className="section-paragraph"
								>
									{slideThree?.slideThreeParagraph}
								</Typography>
							</Grid>
						</>
					)}
				</Grid>
				<Grid
					sx={{ backgroundColor: slideFour?.slideFourColor }}
					container
					className="section-wrapper"
					justifyContent="flex-start"
					alignItems="center"
				>
					{!mobile ? (
						<>
							<Grid className="section-image" item>
								<img className="image" src="/img/how.svg" />
							</Grid>
							<Grid className="section-content" item container >
								<Typography
									sx={{
										color: `${slideFour?.slideFourParagraphColor} !important`
									}}
									className="section-title"
								>
									{slideFour?.slideFourTitle}
								</Typography>
								<Typography
									sx={{
										color: `${slideFour?.slideFourParagraphColor} !important`
									}}
									className="section-paragraph"
								>
									{slideFour?.slideFourParagraph}
								</Typography>
								<Link to="/#contact" className="button-link">
									<Button className="section-btn">Get in touch</Button>
								</Link>
							</Grid>
						</>
					) : (
						<>
							<Grid className="section-content" item>
								<Typography
									sx={{
										color: `${slideFour?.slideFourParagraphColor} !important`
									}}
									className="section-title"
								>
									{slideFour?.slideFourTitle}
								</Typography>
							</Grid>
							<Grid className="section-image" item>
								<img className="image" src="/img/how.svg" />
							</Grid>
							<Grid className="section-content-mobile" item container direction="column" justifyContent="center">
								<Typography
									sx={{
										color: `${slideFour?.slideFourParagraphColor} !important`
									}}
									className="section-paragraph"
								>
									{slideFour?.slideFourParagraph}
								</Typography>
								<Link to="/#contact" className="button-link">
									<Button className="section-btn">Get in touch</Button>
								</Link>
							</Grid>
						</>
					)}
				</Grid>
			</Carousel>
		</div>
	);
};

export default Approach;
