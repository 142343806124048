import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';

import SEO from '../components/SEO';
import Approach from '../components/Approach';

// eslint-disable-next-line
export const ApproachTemplate = ({ id, slideOne, slideTwo, slideThree, slideFour, speakToUs }) => {
	return (
		<div style={{ overflowX: 'hidden' }}>
			<SEO
				title="Play Retail Ltd | Retail Design Agency | London"
				description="We’re Play, a strategic retail design agency who utilises technology to deliver experiential shopper experiences, disrupting the future of retail. "
				image={`${id}.jpg`}
			/>
			<Approach
				slideOne={slideOne}
				slideTwo={slideTwo}
				slideThree={slideThree}
				slideFour={slideFour}
			/>
		</div>
	);
};

ApproachTemplate.propTypes = {
	title: PropTypes.string,
	helmet: PropTypes.object
};

const ApproachPage = ({ data }) => {
	const { markdownRemark: approach } = data;

	return (
		<Layout>
			<ApproachTemplate
				id={approach.id}
				html={approach.html}
				slideOne={approach.frontmatter.slideOne}
				slideTwo={approach.frontmatter.slideTwo}
				slideThree={approach.frontmatter.slideThree}
				slideFour={approach.frontmatter.slideFour}
				speakToUs={approach.frontmatter.speakToUs}
				footer={approach.frontmatter.footer}
			/>
		</Layout>
	);
};

ApproachPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.object
	})
};

export default ApproachPage;

export const pageQuery = graphql`
	query Approach {
		markdownRemark(frontmatter: { templateKey: { eq: "approach" } }) {
			id
			frontmatter {
				slideOne {
					slideOneImage
					slideOneColor
					slideOneTitle
					slideOneParagraphColor
					slideOneParagraph
				}
				slideTwo {
					slideTwoImage
					slideTwoColor
					slideTwoTitle
					slideTwoParagraphColor
					slideTwoParagraph
				}
				slideThree {
					slideThreeImage
					slideThreeColor
					slideThreeTitle
					slideThreeParagraphColor
					slideThreeParagraph
				}
				slideFour {
					slideFourImage
					slideFourColor
					slideFourTitle
					slideFourParagraphColor
					slideFourParagraph
				}
				speakToUs {
					title
					paragraph
					button
				}
				footer {
					email
					phone
					address
					lineOne
					lineTwo
					linkOne
					linkTwo
					copyright
				}
			}
		}
	}
`;
