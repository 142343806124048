import { css as styled } from '@emotion/react';

const styling = theme => styled`

    position: relative;

    &.section-wrapper-inverted {
        .carousel .control-dots .dot {
            background: #fff !important;
            user-select: none;
        }

        .control-arrow {
            padding: 0 !important;
        }

        .control-arrow.control-prev::before {
            border-right: 3px solid transparent !important;
            border-left: 3px solid #fff !important;
            border-top: 3px solid transparent !important;
            border-bottom: 3px solid #fff !important;
            user-select: none;
            margin: 0 !important;
            left: 10px;
        }
    
        .control-arrow.control-next::before {
            border-right: 3px solid transparent !important;
            border-left: 3px solid #fff !important;
            border-top: 3px solid transparent !important;
            border-bottom: 3px solid #fff !important;
            user-select: none;
            margin: 0 !important;
            right: 10px;
        }
    }

    .carousel.carousel-slider li.slide.selected {
        z-index: 0 !important;
    }

    .slider {
        position: relative;
    }

    .thumbs-wrapper {
        height: 0;
        padding: 0;
        margin: 0 !important;
    }

    .dot {
        width: 14px !important;
        height: 14px !important;
        position: relative;
        top: -14px;
        background: #222 !important;
        box-shadow: none !important;
        transition: 0.3s ease;
        user-select: none;
        outline: none;
        -webkit-tap-highlight-color: transparent;
    }

    .control-arrow {
        padding: ${theme.spacing(2)} !important;
        width: 56px;
    }

    .control-arrow.control-prev {
        margin-left: 30px;
        padding: 0 !important;
        -webkit-tap-highlight-color: transparent;

        ${theme.breakpoints.down('sm')} {
            margin-left: 24px;
        }
    }

    .control-arrow.control-next {
        margin-right: 30px;
        padding: 0 !important;
        -webkit-tap-highlight-color: transparent;

        ${theme.breakpoints.down('sm')} {
            margin-right: 24px;
        }
    }

    .control-arrow.control-prev::before {
        border-right: 3px solid transparent !important;
        border-left: 3px solid #222 !important;
        border-top: 3px solid transparent !important;
        border-bottom: 3px solid #222 !important;
        width: 20px;
        height: 20px;
        transform: rotate(45deg);
        transition: 0.3s ease;
        margin: 0 !important;
        user-select: none;
        position: relative;
        left: 10px;
    }

    .control-arrow.control-next::before {
        border-right: 3px solid transparent !important;
        border-left: 3px solid #222 !important;
        border-top: 3px solid transparent !important;
        border-bottom: 3px solid #222 !important;
        width: 20px;
        height: 20px;
        transform: rotate(-135deg);
        tarnsition: 0.3s ease;
        margin: 0 !important;
        user-select: none;
        position: relative;
        right: 10px;
    }

    .control-arrow:hover {
        background: none !important;
    }

    .section-wrapper {
        flex-wrap: nowrap;
        flex-direction: row;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        width: 100%;

        ${theme.breakpoints.up(2050)} {
            padding: ${theme.spacing(0, 50)};
        }

        ${theme.breakpoints.up(1500)} {
            padding: ${theme.spacing(0, 20)};
        }

        ${theme.breakpoints.down(1000)} {
            padding-top: 5vh;
            justify-content: flex-start;
            flex-direction: column;
            flex-flow: column;
            width: 100vw;
        }

    }

    .section-image {
        position-relative;
        width: 100%;
        height: 100%;
        display: flex;

        ${theme.breakpoints.up('xl')} {
            max-width: 100%;
            justify-content: center;
        }

        ${theme.breakpoints.down(1000)} {
            display: flex;
            justify-content: center;
            max-width: 500px;
            width: 300px;
            height: 300px;
            margin-bottom: 3vh;
        }

    }

    .image {
        min-width: 200px;
        max-width: 550px;
        width: 100%;
        position: relative;
        display: flex;

        ${theme.breakpoints.down(1500)} {
            max-width: 500px;
            left: 100px;
        }

        ${theme.breakpoints.down(1000)} {
            margin-top: 5%;
            width: 100%;
            height: auto;
            left: 0;
        }

        ${theme.breakpoints.down(500)} {
            max-width: 250px;
            height: auto;
            left: 0;
        }

    }

    .section-slide {
        padding: ${theme.spacing(8)};
        height: 100vh;
        
    }

    .section-content {
        max-width: 40%;
        min-height: 350px;
        text-align: left;

        ${theme.breakpoints.down(1000)} {
            max-width: 100%;
            min-height: 0;
            max-height: 70px;
            height: 100%;
            font-size: 3rem;
            line-height: 3.2rem !important;
            font-weight: 600 !important;
            margin-bottom: 3vh;
        }

    }

    .section-content-mobile {
        min-height: 300px;
        flex-flow: column;
        justify-content: flex-start;

        ${theme.breakpoints.down(600)} {
            min-height: 200px;
        }
    }

    .section-title {
        color: #fff;
        font-size: 3rem;
        line-height: 3.2rem !important;
        font-weight: 600 !important;
        padding-bottom: ${theme.spacing(8)};

        ${theme.breakpoints.down(1000)} {
            padding-top: ${theme.spacing(4)};
            font-size: 3rem;
            width: 100%;
        }

        ${theme.breakpoints.down(650)} {
            font-size: 2rem;
        }

        ${theme.breakpoints.down(400)} {
            font-size: 2rem;
        }
    }

    .section-paragraph {
        line-height: 1.8rem;
        font-family: 'Comfortaa', sans-serif !important;
        font-weight: 800 !important;
        min-height: 160px;
        width: 80%;

        ${theme.breakpoints.up(1800)} {
            font-size: 1.5rem;
            line-height: 50px;
            max-width: 80%;
        }

        ${theme.breakpoints.down(1000)} {
            font-size: 1.4rem;
            font-weight: 100 !important;
            min-height: 0;
            width: 70%;
            margin: 0 auto;
        }

        ${theme.breakpoints.down(600)} {
            width: 90%;
            font-size: 1.1rem;
        }

        ${theme.breakpoints.down(500)} {
            max-width: 85%;
            font-size: 1.05rem;
            line-height: 1.2rem;
            position: relative;
            // top: -10px;
        }
    }

    .button-link {
        text-decoration: none;
        height: 70px;

        ${theme.breakpoints.down(500)} {
            position: relative;
        }
    }

    .section-btn {
        background-color: #ea5758;
        color: white;
        border-radius: 50px;
        font-size: 0.8rem;
        font-weight: 100;
        width: 200px;
        height: 50px;
        text-transform: none;
        margin-top: ${theme.spacing(5)};
        padding-bottom: 4px;
        transition: 0.3s ease;

        .section-btn:hover {
            background-color: #1F212D;
        }

        ${theme.breakpoints.down('md')} {
            margin-top: ${theme.spacing(3)};
        }

        ${theme.breakpoints.up(1800)} {
            font-size: 1.1rem;
            width: 240px;
            height: 70px;
        }

        ${theme.breakpoints.down(500)} {
            position: relative;
        }
    }

`;

export default styling;
